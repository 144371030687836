<template>
  <div class="footer--find">
    <div class="footer--title mb-3">
      {{ $t('general.mortgage') }}
    </div>
    <div class="d-flex flex-wrap mb-3">
      <router-link to="/kalkulator" class="footer--nav mb-2">
        <img
          src="https://beliruma.s3.ap-southeast-1.amazonaws.com/public/static/img/bank/BRI_2020.svg"
          class="mortgage--image"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.mortgage--image {
  width: 96px;
  height: 48px;
  object-fit: contain;
}
</style>
