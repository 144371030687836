<template>
  <div class="container footer--directory">
    <div class="footer--title mb-3">
      {{ $t('footer.directory.header') }}
    </div>
    <div class="d-flex flex-wrap">
      <div class="d-flex flex-wrap mb-3 footer--nav-wrapper">
        <router-link
          :to="house.url"
          class="footer--nav mb-2"
          v-for="house in houses"
          :key="house.url"
        >
          {{ `${$t('footer.find.house')} ${house.name}` }}
        </router-link>
        <router-link to="/direktori/rumah" class="footer--nav mb-2">
          {{ `${$t('home.seeAll')} ${$t('footer.find.house')} >` }}
        </router-link>
      </div>
      <div class="d-flex flex-wrap mb-3 footer--nav-wrapper">
        <router-link
          :to="apartment.url"
          class="footer--nav mb-2"
          v-for="apartment in apartments"
          :key="apartment.url"
        >
          {{ `${$t('footer.find.apartment')} ${apartment.name}` }}
        </router-link>
        <router-link to="/direktori/apartemen" class="footer--nav mb-2">
          {{ `${$t('home.seeAll')} ${$t('footer.find.apartment')} >` }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    houses: [
      {
        url: '/cari/rumah/Kabupaten-Bekasi/?category=city&category_filter_id=3216',
        name: 'Kabupaten Bekasi',
      },
      {
        url: '/cari/rumah/Kota-Bekasi/?category=city&category_filter_id=3275',
        name: 'Kota Bekasi',
      },
      {
        url: '/cari/rumah/Kabupaten-Bogor/?category=city&category_filter_id=3201',
        name: 'Kabupaten Bogor',
      },
      {
        url: '/cari/rumah/Kota-Bogor/?category=city&category_filter_id=3271',
        name: 'Kota Bogor',
      },
      {
        url: '/cari/rumah/Kabupaten-Tangerang/?category=city&category_filter_id=3603',
        name: 'Kabupaten Tangerang',
      },
      {
        url: '/cari/rumah/Kota-Depok/?category=city&category_filter_id=3276',
        name: 'Kota Depok',
      },
    ],
    apartments: [
      {
        url: '/cari/apartemen/Kabupaten-Bekasi/?category=city&category_filter_id=3216',
        name: 'Kabupaten Bekasi',
      },
      {
        url: '/cari/apartemen/Kota-Bekasi/?category=city&category_filter_id=3275',
        name: 'Kota Bekasi',
      },
      {
        url: '/cari/apartemen/Kabupaten-Bogor/?category=city&category_filter_id=3201',
        name: 'Kabupaten Bogor',
      },
      {
        url: '/cari/apartemen/Kota-Bogor/?category=city&category_filter_id=3271',
        name: 'Kota Bogor',
      },
      {
        url: '/cari/apartemen/Kabupaten-Tangerang/?category=city&category_filter_id=3603',
        name: 'Kabupaten Tangerang',
      },
      {
        url: '/cari/apartemen/Kota-Depok/?category=city&category_filter_id=3276',
        name: 'Kota Depok',
      },
    ],
  }),
};
</script>

<style lang="scss" scoped></style>
